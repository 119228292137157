$(document).ready(function () {

  // Toggle Active
  function setActive() {
    $(this).toggleClass('active-item');
    $(this).find('.item-answer').slideToggle(150);
    
    var totalItems = $(this).parents('.faq-accordion').find('.faq-item').length;
    var activeItems = $(this).parents('.faq-accordion').find('.active-item').length;
    var expandBtn = $(this).parents('.faq-accordion').find('.expand-items').first();
    var collapseBtn = $(this).parents('.faq-accordion').find('.collapse-items').first();
    if ( activeItems >= totalItems) {
      expandBtn.addClass('d-none');
      collapseBtn.removeClass('d-none');
    } else {
      expandBtn.removeClass('d-none');
      collapseBtn.addClass('d-none');
    }
  }

  // Expand Items
  function expandItems() {
    var faqItems = $(this).parents('.faq-accordion').find('.faq-item');
    faqItems.addClass('active-item');
    faqItems.find('.item-answer').slideDown(150);
    $(this).toggleClass('d-none');
    $(this).siblings().toggleClass('d-none');
  }

  // Collapse Cards
  function collapseItems() {
    var faqItems = $(this).parents('.faq-accordion').find('.faq-item');
    faqItems.removeClass('active-item');
    faqItems.find('.item-answer').slideUp(150);
    $(this).toggleClass('d-none');
    $(this).siblings().toggleClass('d-none');
  }

  // Init
  $('.faq-accordion').each(function() {
    $(this).find('.faq-item').on('click', setActive);
    $(this).find('.expand-items').on('click', expandItems);
    $(this).find('.collapse-items').on('click', collapseItems);
  });
});