$(document).ready(function () {
  var changeMode = '';

  function setupTopicSelector() {
    var viewportWidth = $(window).width();

    if (viewportWidth >= 992) {
      changeMode = 'desktop';
    } else {
      changeMode = 'mobile';
    }

    adjustTopicSelector();
  }

  function adjustTopicSelector() {
    // Run the correct behavior based on viewport and set changeMode so we don't keep firing on window resize
    var viewportWidth = $(window).width();
    if (viewportWidth >= 992 && changeMode == 'desktop') {
      changeMode = 'mobile';
      runTopicSelectorDesktop();
    } else if (viewportWidth <= 991 && changeMode == 'mobile') {
      changeMode = 'desktop';
      runTopicSelectorMobile();
    }
  }

  // Mobile function
  function runTopicSelectorMobile() {
    $('.topic-selector').each(function() {
      var thisBlade = $(this);

      // Reset blade
      thisBlade.find('.topic-selector-item, .tab-wrapper').unbind().removeClass('active-item');
      thisBlade.find('.bucket').each(function() {
        var thisParent = $(this).data('parent');
        thisBlade.find(thisParent).parent().append($(this));
        $(this).css('display','none');
      });
      
      // Add interactivity
      thisBlade.find('.topic-selector-item').on('click', function() {
        $(this).toggleClass('active-item');
        $(this).find('.bucket').slideToggle(150);
      });
    });
  }

  // Desktop function
  function runTopicSelectorDesktop() {
    $('.topic-selector').each(function() {
      var thisBlade = $(this);
      console.log(thisBlade);

      // Reset blade
      thisBlade.find('.topic-selector-item, .tab-wrapper').unbind().removeClass('active-item');
      thisBlade.find('.bucket').each(function() {
        $(this).css('display','none');
        thisBlade.find('.topic-selector-content').append($(this));
      })

      // Add interactivity
      thisBlade.find('.tab-wrapper').on('click', function() {
        var theTarget = $(this).data('target');
        console.log(theTarget);
        
        thisBlade.find('.topic-selector-item').removeClass('active-item');
        thisBlade.find('.bucket').css('display','none');

        $(this).parents('.topic-selector-item').addClass('active-item');
        thisBlade.find(theTarget).css('display','block');
      });

      // Set first state
      thisBlade.find('.topic-selector-item').first().addClass('active-item');
      thisBlade.find('.bucket').first().css('display','block');
    });
  }

  // Rerun function on resize
  $(window).resize(function () {
    adjustTopicSelector();
  });

  // First Initialize
  setupTopicSelector();
});