// Resource Accordion js
$('.r-accordion .tease-doc-links').on('click', function() {
  var thisParent = $(this).parent();
  var thisInfo = thisParent.find('.info-expand');
  // if the resource clicked is currently active
  if(thisParent.hasClass('active')) {
    thisParent.removeClass('active');
    thisParent.find('.fa-minus.info-click').addClass('fa-plus').removeClass('fa-minus');
    thisInfo.slideUp(400);
    return;
  }
  // if it is NOT currently active, Activate new resource
  var currActive = $('.resource-row.active');
  // If there is one already active
  if (currActive) {
    currActive.find('.fa-minus.info-click').removeClass('fa-minus').addClass('fa-plus');
    currActive.find('.info-expand').slideUp(400);
    currActive.removeClass('active');
  }
  // Activate the new resource
  thisParent.addClass('active');
  thisParent.find('.fa-plus.info-click').removeClass('fa-plus').addClass('fa-minus');
  thisInfo.slideDown(400);
});