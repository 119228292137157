$(document).ready(function() {
  
  $('.reviewCards').each(function() {
    var thisBlade = $(this);
    
    if (thisBlade.find('.reviewCard').length > 1) {
      thisBlade.addClass('reviewCards-2up');
    }
  });
  
  // $('.reviewCards-2up').find('.reviewCard').each(function() {
  //   var thisCard = $(this);

  //   // Move review source items
  //   thisCard.find('.reviewSource2up').append(thisCard.find('.reviewSource'));
  //   thisCard.find('.reviewSource2up').append(thisCard.find('.reviewIcon'));

  // });
});