$(document).ready(function () {
  var changeMode = '';

  function setupFaq() {
    var viewportWidth = $(window).width();

    if (viewportWidth >= 992) {
      changeMode = 'desktop';
    } else {
      changeMode = 'mobile';
    }

    adjustFaq();
  }

  function adjustFaq() {
    // Run the correct behavior based on viewport and set changeMode so we don't keep firing on window resize
    var viewportWidth = $(window).width();
    if (viewportWidth >= 992 && changeMode == 'desktop') {
      changeMode = 'mobile';
      runFaqDesktop();
      console.log('desktop');
    } else if (viewportWidth <= 991 && changeMode == 'mobile') {
      changeMode = 'desktop';
      runFaqMobile();
      console.log('mobile');
    }
  }

  // Mobile function
  function runFaqMobile() {
    $('.faq-topicSelector').each(function() {
      var thisBlade = $(this);

      // Reset blade
      thisBlade.find('.faq-item, .question-wrapper').unbind().removeClass('active-item');
      thisBlade.find('.answer').each(function() {
        var thisParent = $(this).data('parent');
        thisBlade.find(thisParent).parent().append($(this));
        $(this).css('display','none');
      });
      
      // Add interactivity
      thisBlade.find('.faq-item').on('click', function() {
        $(this).toggleClass('active-item');
        $(this).find('.answer').slideToggle(150);
      });
    });
  }

  // Desktop function
  function runFaqDesktop() {
    $('.faq-topicSelector').each(function() {
      var thisBlade = $(this);

      // Reset blade
      thisBlade.find('.faq-item, .question-wrapper').unbind().removeClass('active-item');
      thisBlade.find('.answer').each(function() {
        $(this).css('display','none');
        thisBlade.find('.faq-content').append($(this));
      })

      // Add interactivity
      thisBlade.find('.question-wrapper').on('click', function() {
        var theTarget = $(this).data('target');

        thisBlade.find('.faq-item').removeClass('active-item');
        thisBlade.find('.answer').css('display','none');

        $(this).parents('.faq-item').addClass('active-item');
        thisBlade.find(theTarget).css('display','block');
      });

      // Set first state
      thisBlade.find('.faq-item').first().addClass('active-item');
      thisBlade.find('.answer').first().css('display','block');
    });
  }

  // Rerun function on resize
  $(window).resize(function () {
    adjustFaq();
  });

  // First Initialize
  setupFaq();
});