$(document).ready(function () {
    $(".carousel-wrapper").each(function () {
        var uCarousel = $(this);
        uCarousel.find('.carousel-list').each(function () {
            $(this).slick({
                dots: true,
                appendDots: $(this).parents('.carousel-wrapper'),
                infinite: false,
                speed: 300,
                slidesToShow: 3.2,
                slidesToScroll: 1,
                adaptiveHeight: true,
                adaptiveWidth: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2.5,
                            infinite: false,
                            adaptiveWidth: false,

                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            infinite: false,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            infinite: false,
                        }
                    }
                ]
            });
        });

        uCarousel.find('.back').on('click', function () {
            var videoStrip = $(this).parents('.carousel-wrapper').find('.carousel-list').slick('slickPrev');
        });

        uCarousel.find('.forward').on('click', function () {
            var videoStrip = $(this).parents('.carousel-wrapper').find('.carousel-list').slick('slickNext');
        });

        /**
        *  Begin code for hiding dots and arrows based
        *  on number of videos
        **/ 
        var carouselItemCount = uCarousel.find('.carousel-item').length;

        if(uCarousel.length) {

            if(carouselItemCount < 4) {
                uCarousel.find('.back').addClass('transparent');
                uCarousel.find('.forward').addClass('transparent');

                // Check for the size of the window and add/remove transparent class
                $(window).on("resize", function() {
                    if($(window).width() > 992) {
                        uCarousel.find('.slick-dots').addClass('transparent');
                    }
                    else {
                        uCarousel.find('.slick-dots').removeClass('transparent');
                    }
                }).resize();
            }
            else {
                var currentSlide;
                var slidesCount;

                uCarousel.find('.back').addClass('transparent');

                var updateSlideCounter = function(slick, currentIndex) {
                    currentSlide = slick.slickCurrentSlide();
                    slidesCount = slick.slideCount;

                        if(currentSlide == 0){
                            uCarousel.find('.back').addClass('transparent');
                            uCarousel.find('.forward').removeClass('transparent');
                        }
                        else if(currentSlide == slidesCount-3) {
                            uCarousel.find('.back').removeClass('transparent');
                            uCarousel.find('.forward').addClass('transparent');
                        }
                        else if((currentSlide > 0) && (currentSlide < slidesCount)){
                            uCarousel.find('.back').removeClass('transparent');
                            uCarousel.find('.forward').removeClass('transparent');
                        }
                }

                uCarousel.on('init', function(event, slick){
                    updateSlideCounter(slick);
                    if (carouselItemCount < 4) {
                        widthChange(mq);
                    }
                });

                uCarousel.on('afterChange', function(event, slick, currentSlide) {
                    updateSlideCounter(slick, currentSlide);
                    if (carouselItemCount < 4) {
                        widthChange(mq);
                    }
                });
            }
        }

        /**
        *  End code for hiding dots and arrows based
        *  on number of videos
        **/
    });
});