$(document).ready(function () {
  var modalContent = $('#mcui-modal-content');

  // Used in next function if running tests. Please keep this function.
  function getTestStatus() {
    if (typeof isModalTest !=='undefined') {
      console.log('test true');
      return '&test=true';
    } else {
      console.log('test false');
      return '';
    }
  }

  function getIFrameSrc(modalType, docID) {
      var languagePrefix = AppState.GetCurrentLanguageUrlPrefix();
      switch (modalType) {
          case 'resource': return languagePrefix + '/resources/mcui-embedded-form?docs=' + docID; // No longer testing.
          case 'video': return languagePrefix + '/resources/mcui-video-form?c=' + docID + getTestStatus();
          case 'youtube': return 'https://www.youtube.com/embed/' + docID + '?rel=0&autoplay=1';
          case 'roi-calculator': return '/resources/mcui-embedded-form?roi=' + docID;
      }
  }

  function displayModal(e) {
      // Gather key components from clicked trigger
      e.preventDefault();
      var modalType = $(this).data('modal-type');
      var docID = $(this).data('docid');

      var iframeSrc = getIFrameSrc(modalType, docID);
      var iframeHtml = "";

      // Generate content markup based on the type of modal triggered
      if (modalType === "youtube") {
          iframeHtml = '<div class="embed-responsive embed-responsive-16by9"><iframe width="515" height="290" src="' + iframeSrc + '" frameborder="0" allow="autoplay"></iframe></div>';
          resizeIframe();
      } else if (modalType === "roi-calculator") {
        iframeHtml = '<iframe id="mcui-modal-iframe" class="roi-calc-iframe embed-responsive-item" src="' + iframeSrc + '">';
          var resizeTimer = setInterval(resizeIframe, 300);
      } else {
          iframeHtml = '<iframe id="mcui-modal-iframe" class="embed-responsive-item" src="' + iframeSrc + '">';
          var resizeTimer = setInterval(resizeIframe, 300);
      }
      modalContent.html(iframeHtml);
      hideSpinner();
  }

  // Helper functions to fire the iFrame resizing on loads and viewport adjustment
  function resizeIframe() {
    var theFrame = document.getElementById('mcui-modal-iframe');
    if (theFrame) {
      var offsetHeight = (((theFrame.contentWindow || {}).document || {}).body || {}).offsetHeight;
      if (offsetHeight) {
        theFrame.style.height = theFrame.contentWindow.document.body.offsetHeight + 'px';
      }
    }
    // var e = document.getElementById("mcui-modal-iframe");
    // if (e){
    //   var offsetHeight = (((e.contentWindow || {}).document || {}).body || {}).offsetHeight;
    //   e.style.height = offsetHeight + "px"
    // };
  }

  // Hide spinner when iFrame loads
  function hideSpinner() {
      var theFrame = $('#mcui-modal-iframe');
      theFrame.on('load', function () {
          $('#loader').removeClass('d-flex').addClass('d-none');
      });
  }

  // Bind modal display function to all <a> with ID for modal
  $(document).on('click', '[href="#mcui-modal"]', displayModal);

  // Shut of videos on modal close
  $('#mcui-modal').on('hidden.bs.modal', function () {
    var roiIframe = $('#mcui-modal-iframe');
    if ( roiIframe.hasClass('roi-calc-iframe') ) {
      console.log('True');
    } else {
      modalContent.html('');
    }
    $('#loader').removeClass('d-none').addClass('d-flex');
  });
});